// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-electrodes-js": () => import("./../src/pages/electrodes.js" /* webpackChunkName: "component---src-pages-electrodes-js" */),
  "component---src-pages-gouging-carbon-js": () => import("./../src/pages/gouging-carbon.js" /* webpackChunkName: "component---src-pages-gouging-carbon-js" */),
  "component---src-pages-graphite-materials-js": () => import("./../src/pages/graphite-materials.js" /* webpackChunkName: "component---src-pages-graphite-materials-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-refractories-js": () => import("./../src/pages/refractories.js" /* webpackChunkName: "component---src-pages-refractories-js" */)
}

