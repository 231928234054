import React from 'react';

export const GlobalStateContext = React.createContext();
export const GlobalDispatchContext = React.createContext();

const initialState = {
  hasLoaded: false,
  menuOpen: false,
  navColor: "rgba(8, 8, 8, 0)",
};

function reducer(state, action) {
  switch (action.type) {
    case 'PAGE_LOAD': {
      return {
        ...state,
        hasLoaded: true,
      }
    }

    case 'NAV_COLOR_CHANGE': {
      return {
        ...state,
        navColor: action.color,
      }
    }

    case 'MENU_TOGGLE': {
      return {
        ...state,
        menuOpen: !state.menuOpen,
      }
    }

    case 'MENU_OPEN': {
      return {
        ...state,
        menuOpen: true,
      }
    }

    case 'MENU_CLOSE': {
      return {
        ...state,
        menuOpen: false,
      }
    }

    default:
      throw new Error('Bad action type');
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return <GlobalStateContext.Provider value={state}>
    <GlobalDispatchContext.Provider value={dispatch}>
      {children}
    </GlobalDispatchContext.Provider>
  </GlobalStateContext.Provider>
};

export default GlobalContextProvider;